import { defineStore, acceptHMRUpdate } from 'pinia';
import * as api from '@/api';
import { useShowRoleProspectStore } from '@/stores/show_role_prospects';
import { useShowStore } from '@/stores/shows';
import { useActorStore } from '@/stores/actors';
import {
  doLoading,
  addUpdateRecord,
  buildChanges,
  clearEmpty,
  isExisting,
  removeRecord,
} from '@/utilities/helpers';

export const useShowRoleStore = defineStore('showRoles', {
  state: () => ({
    /** @type {number} */
    loading: 0,
    /** @type {{id: string}[]} */
    records: [],
  }),
  getters: {
    count: (state) => state.records.length,
    retrieve: (state) => (showRoleId) => state.records.find((v) => v.id === showRoleId),
    isLoading: (state) => state.loading > 0,
    forShowId: (state) => (showId) => state.records.filter((v) => v.showId === showId),
    forActorId: (state) => (actorId) => state.records.filter((v) => v.actorId === actorId),
  },
  actions: {
    async processRecord(record) {
      await useShowRoleProspectStore().processRecords(record.prospects);
      if (record.show) {
        await useShowStore().processRecord(record.show);
      }
      if (record.actor) {
        await useActorStore().processRecord(record.actor);
      }
      return addUpdateRecord(this.records, record, {
        skip: ['prospects', 'show', 'actor'],
      });
    },
    async processRecords(records) {
      if (records) {
        for (const record of records) {
          await this.processRecord(record);
        }
      }
    },
    async removeRecord(showRoleId) {
      await useShowRoleProspectStore().removeRecordsBy({ showRoleId });

      removeRecord(this.records, showRoleId);
    },
    async removeRecordsBy({ showId }) {
      if (showId) {
        const toRemove = this.forShowId(showId).map((e) => e.id);
        for (const toRemoveId of toRemove) {
          await this.removeRecord(toRemoveId);
        }
      }
    },
    async retrieveAsync(showRoleId, {
      force = false,
      withProspects = false,
      withActors = false,
      withShows = false,
    } = {}) {
      let record = this.records.find((v) => v.id === showRoleId);
      if (!record || force) {
        await doLoading(this, async () => {
          const params = { showRoleId, withActors, withProspects, withShows };
          record = await api.showRoles.retrieve(params);
          if (record) {
            record = await this.processRecord(record);
          }
        });
      }
      return record;
    },
    async reorder(showRoleId, position, relatedShowRoleId = null) {
      if (isExisting(showRoleId)) {
        const payload = {
          showRoleId,
          position,
        };
        if (relatedShowRoleId) {
          payload.relatedShowRoleId = relatedShowRoleId;
        }
        return await doLoading(this, async () => {
          return await api.showRoles.reorder(payload).then(async (data) => {
            await this.processRecords(data.updatedShowRoles);
            return this.processRecord(data.showRole);
          });
        });
      } else {
        throw new Error('Show role ID does not exists');
      }
    },
    async saveChanges(showRoleId, updates, options = {}) {
      const payload = { showRoleId, updates, options };

      return await doLoading(this, async () => {
        if (Object.keys(payload.updates).length > 0) {
          return await api.showRoles.update(payload).then(async (data) => {
            if (data.showRoleProspect) {
              useShowRoleProspectStore().processRecord(data.showRoleProspect);
            }
            return this.processRecord(data.showRole);
          });
        } else {
          return this.retrieve(showRoleId);
        }
      });
    },
    async save(showRole, extra = null, options = {}) {
      if (isExisting(showRole.id)) {
        // existing
        const original = this.retrieve(showRole.id);
        const changes = buildChanges(original, showRole, {
          skip: ['id', 'updatedAt'],
        });

        return this.saveChanges(showRole.id, { ...changes, ...extra }, options);
      } else {
        // new showRole
        const prepared = {
          showRole: clearEmpty(showRole),
        };
        return await doLoading(this, async () => {
          return await api.showRoles.create(prepared).then(async (data) => {
            return this.processRecord(data.showRole);
          });
        });
      }
    },
    async setActorId(showRoleId, actorId) {
      const original = this.retrieve(showRoleId);
      return await this.saveChanges(showRoleId, buildChanges(original,
        { actorId: actorId },
        {
          only: ['actorId'],
        }));
    },
    async delete(showRoleId) {
      const showRole = this.retrieve(showRoleId);
      if (!showRole) {
        return;
      }
      const payload = { showRoleId };

      return await doLoading(this, async () => {
        return await api.showRoles.delete(payload).then(async data => {
          await this.removeRecord(data.showRoleId);
          return data.showRoleId;
        });
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShowRoleStore, import.meta.hot));
}
